import { combineReducers } from 'redux';
import auth from './auth';
import barSize from './barSize';
import coinData from './cmcCoins';
import publicList from './publicList';

export default combineReducers({
    auth: auth,
    barSize: barSize,
    coinData: coinData,
    publicList: publicList,
});
