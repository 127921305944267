
import { Alert, Box, Stack } from '@mui/material';
import Lottie from 'react-lottie-player';
import emptyBox from '../assets/629-empty-box.json';


const DefaultManage = () => {

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Lottie
            loop
            animationData={emptyBox}
            play
            style={{ width: 400, height: 400 }}
            />
        </Box>
    )
};

export default DefaultManage;
