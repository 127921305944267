
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet';

export const DrawChart = ({
    width = 800,
    height = 600,
    symbol = 'AAPL',
    watchlist = ["AAPL","AMZN","FB","GOOG","MSFT"],
    interval = 'D',
    darkMode = true,
    style = '1'
}) => {

    const theme = darkMode ? 'dark' : 'light';

    return (
        <div>
        <div id="watchlist"></div>
        <Helmet>
            <script type="text/javascript">{`
                new window.TradingView.widget({
                    "width": ` + width + `,
                    "height": ` + height + `,
                    "symbol": "` + symbol + `",
                    "interval": "` + interval + `",
                    "timezone": "Etc/UTC",
                    "theme": "` + theme + `",
                    "style": "1",
                    "locale": "en",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": true,
                    "allow_symbol_change": true,
                    "hide_top_toolbar": false,
                    "watchlist": ` + JSON.stringify(watchlist) + `,
                    "container_id": "watchlist"
                })
            `}
            </script>
        </Helmet>
        </div>
    )
}
