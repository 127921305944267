
import React from "react";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

export const PageNotFound = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
        }}>
        <h3>Page not found!</h3>
        </div>
    );
}


export const ServerErrorMessage = () => {
    return (
        <div style={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
        <h6>Server failure. Please try again later.</h6>
        <br/>
        <BrokenImageIcon color='error' sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            fontSize: '100px',
        }}></BrokenImageIcon>
        </div>
    );
}
