
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL;

export const loadPublicList = () => dispatch => {

    const url = `${API_URL}/api/publicListView/`;

    axios.get(url, {params: {}})
    .then( (res) => {
        dispatch({
            type: 'LOADING_PUBLIC_LIST_SUCCESS',
            payload: res.data.savedResults,
        });
        // console.log(res.data.savedResults)

    })
    .catch ( (err) => {
        dispatch({
            type: 'LOADING_PUBLIC_LIST_FAIL',
        });
        // console.log(err);
    }
    )
};
