import React from "react"
import { NavLink } from "react-router-dom";
import styled from "styled-components"
import {
  DropdownSection,
  HeadingLink,
} from "./Components"

const MakeDropdownEl = styled.div`
  width: 15rem;
`

const MakeDropdown = () => {
  return (
    <MakeDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ul>
          <HeadingLink>
            <NavLink to="/make-from-charts">BROWSE CHARTS</NavLink>
          </HeadingLink>
          <HeadingLink>
            <NavLink to="/make-from-lists">BROWSE LISTS</NavLink>
          </HeadingLink>
        </ul>
      </DropdownSection>
    </MakeDropdownEl>
  )
};

export default MakeDropdown
