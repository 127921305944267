
import React,  { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { connect, useSelector, useDispatch } from 'react-redux';

// custom utils
import { DrawChart } from "../charts/TVChart";
import { TopBar } from "../bars/EtfWatchBars";
import { useWindowDimensions } from "../pages/Utils";

// local json files
import etfs from "../data/etfgrouped.json";
import { dark } from "@mui/material/styles/createPalette";


const ETFWatch = ({ navbarSize, topbarSize }) => {

    // handle list of index
    const groupNames = etfs.map(v => v.Name);
    const [selectList, setSelectList] = useState(etfs[0].Name);

    const [groupItems, setGroupItems] = useState(etfs[0].Items);

    const [symbol, setSymbol] = useState(etfs[0].Items[0]);

    const handleWatchlist = (e) => {
        let ln = e.target.value;
        let idx = groupNames.indexOf(ln);
        setGroupItems(etfs[idx].Items);
        setSelectList(ln);
        setSymbol(etfs[idx].Items[0]);
        // handleFocus();
    }

    // show comments canvas
    const [showCanvas, setShowCanvas] = useState(false);
    const closeCanvas = () => setShowCanvas(false);
    const openCanvas = () => setShowCanvas(true);


    // setting interval
    const [interval, setInterval] = useState('D');
    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
        // handleFocus();
    }

    // setting theme
    const [darkMode, setDarkMode] = useState(true);
    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        // console.log(e);
    }

    // get interval from local storag, save interval to local storage
    useEffect(() => {
        let localInterval = JSON.parse(localStorage.getItem('user-interval'));
        if (localInterval) {
            setInterval(localInterval);
        }

        let localTheme = JSON.parse(localStorage.getItem('dark-theme'));
        if (localTheme !== null) {
            setDarkMode(localTheme);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('user-interval', JSON.stringify(interval));
        localStorage.setItem('dark-theme', JSON.stringify(darkMode));
    });

    // setting chart dimensions *** potential to improve ***
    const topHeight = navbarSize + topbarSize + 10
    const chartWidth = useWindowDimensions().width;
    const chartHeight = useWindowDimensions().height;


    return (
        <div>
        <div>
        <TopBar
            interval={ interval }
            darkMode= { darkMode }
            indexList={ groupNames }
            selectList={ selectList }
            handleIntervalChange={ handleIntervalChange }
            handleWatchlist={ handleWatchlist }
            handleThemeChange={ handleThemeChange }
        >
        </TopBar>
        </div>
        
        <Grid container sx={{
            minHeight: chartHeight - topHeight,
            backgroundColor: '#131722',
        }}>
            <Grid item xs={12}>
                <DrawChart
                width={chartWidth}
                height={chartHeight - topHeight}
                symbol={symbol}
                watchlist={ groupItems }
                interval={ interval }
                darkMode={ darkMode }
                ></DrawChart>
            </Grid>
        </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        navbarSize: state.barSize.navbarSize,
        topbarSize: state.barSize.topbarSize,
    }
}
  
export default connect( mapStateToProps ) (ETFWatch);
