
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const LeaderboardSummary = ({ publicWatchlist }) => {

    const [publicResults, setPublicResults] = useState([])

    useEffect( () => {
        if (publicWatchlist) {
            setPublicResults(publicWatchlist.map(l => l.Name))
        }
    }, [])

    useEffect( () => {
        if (publicWatchlist) {
            setPublicResults(publicWatchlist.map(l => l.Name))
        }
    }, [publicWatchlist])
    
    console.log(publicWatchlist)

    return(
        <div>{ publicResults.map(item => 
                (<div>{item}</div>)
        )
        }
        </div>
    )


}

const mapStateToProps = state => ({
    publicWatchlist: state.publicList.publicList,
});

export default connect( mapStateToProps )(LeaderboardSummary);

