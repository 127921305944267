
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logout } from "../actions/auth"

const Logout = ({ logout }) => {

  logout()
  return <Redirect to="/login" />

}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, { logout }) (Logout);