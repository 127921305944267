import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import {
  HeadingLink,
  DropdownSection,
} from "./Components"



const HomeDropdownEl = styled.div`
  width: 15rem;
`

const HomeDropdown = ({ activeItem }) => {

  return (
    <HomeDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ul>
          <HeadingLink>
          <NavLink to="/manage">
            MANAGE
          </NavLink>
          </HeadingLink>
          <HeadingLink>
          <NavLink to="/monitor">
            MONITOR
          </NavLink>
          </HeadingLink>
        </ul>
      </DropdownSection>
    </HomeDropdownEl>
  )
}

export default HomeDropdown
