
const initialState = {
    coinData: localStorage.getItem('coin-data') ? JSON.parse(localStorage.getItem('coin-data'))['data'] : [],
    isFresh: false,
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case 'LOADING_COINS_SUCCESS':
            localStorage.setItem('coin-data', JSON.stringify({'data': payload}));
            return {
                ...state,
                coinData: payload,
                isFresh: true,
            }
        case 'LOADING_COINS_FAIL':
            return {
                ...state,
                isFresh: false,
            }
        default:
            return {
                ...state,
            };
    }
}

