
import React, { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Stack, Snackbar } from "@mui/material";

import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// local utils
import smc from "../data/stocks.json";
import cmc from "../data/coins.json";
import etfs_market_cap from "../data/etfs_market_cap.json"

ChartJS.register(
    ArcElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

const formatMarketCap = n => {
    return (n / 1e9).toFixed(2);
};


const colors = ['#4caf50', '#ffc107', '#f44336', '#2196f3', '#9c27b0', '#607d8b',
    '#673ab7', '#3f51b5', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a',
    '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e'];


const PricePie = ({
    activeList = [],
    chartRef = null,
    width = "100%",
    height = "80vh",
}) => {

    const [successList, setSuccessList] = useState([])
    const [failedList, setFailedList] = useState([])
    const [alertLevel, setAlertLevel] = useState(0);

    useEffect( () => {
        setAlertLevel(0);
        setSuccessList(activeList.filter(item => item['CurrentPrice'] > 0));
        setFailedList(activeList.filter(item => item['CurrentPrice'] === 0));
    }, [activeList])

    // console.log(successList.map(item => item['Symbol']).join(','))

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [alertMsg, setAlertMsg] = useState();

    useEffect( () => {
        if (activeList.length === 0 && failedList.length > 0) {
            setLoading(true);
            setAlertLevel(1);
        } else if (successList.length === 0 && failedList.length > 0) {
            setAlertLevel(2);
        } else if (failedList.length > 0) {
            setAlertLevel(3);
        }

    }, [activeList]);




    // set alert message according to alert level 
    useEffect( () => {
        if (alertLevel === 1) {
            setAlertMsg('Failed to read market data. Please try again later.');
            setShowModal(true);

        } else if (alertLevel === 2) {
            setAlertMsg('Failed to read market data. Please try  again later.');
            setShowModal(true);
        } else if (alertLevel === 3) {
            setAlertMsg('Failed to read market data for ' + failedList.map(item => item['Symbol']).join(', ') );
            setShowModal(true);
        }
    }, [alertLevel]);

    
    // const marketCap = tkrList.map(tkr => smc.find(v => v.Ticker === tkr));
    const marketcapList = activeList.map(item => {
    try{
        const mktCap = smc.find(v => v.Ticker === item['Symbol']).MarketCap;
        return formatMarketCap(mktCap);
    } catch (e) {
        try {
            const mktCap = cmc.find(v => v.Ticker === item['Symbol']).MarketCap;
            return formatMarketCap(mktCap);
        } catch (e) {
            try {
                const mktCap = etfs_market_cap.find(v => v.Ticker === item['Symbol']).MarketCap;
                return formatMarketCap(mktCap)
            } catch (e) {
                return null;
            }
        }
    }
    });

    const priceOptions = {
        responsive: true,
        plugins: {
        legend: {
        position: "top"
        },
        title: {
        display: true,
        text: "PRICE ( USD )",
        },
        datalabels: {
        display: true,
        color: 'white',
        font: {
        weight: 'bold',
        size: '14'
        },
        }        
        },
        maintainAspectRatio: false,
    };

    const marketcapOptions = {
        responsive: true,
        plugins: {
        legend: {
        position: "top"
        },
        title: {
        display: true,
        text: "MARKET CAP ( BILLION )",
        },
        datalabels: {
        display: true,
        color: 'white',
        font: {
        weight: 'bold',
        size: '14'
        },
        }        
        },
        maintainAspectRatio: false,
    };

    const priceChartData = {
        labels: successList.map( item => item['Symbol'] ),
        datasets: [
        {
        label: 'PRICE',
        data: successList.map( (item) => item['CurrentPrice'] ),
        backgroundColor: successList.map( (item, index) => colors[index] ),
        borderColor: successList.map( (item, index) => colors[index] ),
        //   borderWidth: 1,
        }
    ]};

    const marketcapChartData = {
        labels: activeList.map(item => item['Symbol']),
        datasets: [
        {
        label: 'MARKET CAP ( BILLION )',
        data: marketcapList,
        backgroundColor: activeList.map( (item, index) => colors[index] ),
        borderColor: activeList.map( (item, index) => colors[index] ),
        //   borderWidth: 1,
        }
    ]};


    return (
        <div>
            <div>{ loading ?
                <div style={{
                width: width,
                height: height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                <CircularProgress />
                </div> :
                <Stack direction='row' sx={{display: 'flex', justifyContent: 'space-around'}}>
                <div style={{
                width: "40%",
                height: height,
                }}>
                <Pie width="50%" height={height} options={priceOptions} data={priceChartData} ref={chartRef} />
                </div>
                <div style={{
                width: "40%",
                height: height,
                }}>
                <Pie width="50%" height={height} options={marketcapOptions} data={marketcapChartData} ref={chartRef} />            
                </div>
                </Stack>
            }</div>
            <div>
                <Snackbar
                    open={showModal}
                    autoHideDuration={5000}
                    onClose={() => setShowModal(false)}
                    message={alertMsg}
                    action={
                        <Fragment>
                        <Button color="secondary" size="small" onClick={() => setShowModal(false)}>
                            DISMISS
                        </Button>
                        </Fragment>
                    }
                />
            </div>
        </div>
    );  
}

export default PricePie;