
import Parser from 'rss-parser';
import { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


const NewsFeed = () => {

    const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

    let parser = new Parser();
  
    const [feed, setFeed] = useState(null);
    const [load, setLoad] = useState(false);
  
    const handleFeed = async () => {
        // setFeed(await parser.parseURL(CORS_PROXY + 'https://www.reddit.com/.rss'));
        setFeed(await parser.parseURL('https://www.cnbc.com/id/100003114/device/rss/rss.html'));
        setLoad(true);
    };
  
    useEffect(() => {
        handleFeed();
    }, [load]);


    if (feed !== null) {
        // console.log(feed.items[0])
    }
  
    return (
        <div>
        <Paper>
        <div style={{
            height: '100px',
            backgroundColor: '#f5f5f5',
            padding: '20px',
            display: 'flex', 
            justifyContent: 'center'
        }}>
            <Typography variant="h4" component="h4">
                Market News
            </Typography>
        </div>
        </Paper>
        <Paper style={{padding: '5px'}}>
        {
            feed === null? <Box sx={{
                display: 'flex',
                marginTop: '50%',
                marginBottom: '80%',
                justifyContent: 'center'
            }}><CircularProgress /></Box>:
            feed.items.map(
                (item, index) =>
                <div key={index}>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <h6>{item.title}</h6>
                    </a>
                    <p>{item.content}</p>
                    <span>{new Date(item.pubDate).toDateString()}</span>
                    <hr/>
                </div>
            )
        }
        </Paper>
        </div>
    )

};

export default NewsFeed;


