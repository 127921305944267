import React from 'react';
import { Grid } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';

const PrivacyPolicy = () => {
    return (
        <div className='container'>
          <Grid container spacing={1}>
            <Grid item xs={11}><h1>Privacy Policy</h1></Grid>
            <Grid item xs={1}><a href='/'><HomeIcon fontSize='large' /></a></Grid>
          </Grid>
          <p>
            Protecting your private information is our priority. This statement of privacy applies to watchlist101.com and governs data collection and usage. for the purposes of this privacy policy, unless otherwise noticed, all references to watchlist101 include watchlist101.com. The watchlist101.com website is interactive practice site. By using the watchlist101.com website, you can send to the data practices described in this statement.
          </p>
          <h4>Collection of your personal information</h4>
          <p>
            We do not collect any personal information about you unless you voluntarily provide it to us.  However, you may be required to provide certain personal information to us when you use today’s or future products or future services available on the site. These may include: (a)  registering for an account on our site; (b) entering a sweepstakes or contests sponsored by us or one of our  future partners; (d) sending an email message;(e) in future, submitting your credit card or other payment information when ordering purchasing products and services on our site if there will be any. To wit,  we will use your information for, but not limited to, communicating with you and relation to services and or products you have  requested from us. We  may also gather additional personal or non personal information in the future of which we will notify you and/or update this privacy policy.
          </p>
          <h4>Sharing information with third-parties</h4>
          <p>
            watchlist101.com  does not sell, rent or lease its customer list to third parties.<br/>
            watchlist101.com  in the future share data with trusted Partners to help perform statistical analysis, send you email or post mail, provide customer service support, or arrange for deliveries.   All such third parties are prohibited from using your personal information except to provide these services to watchlist101.com, and they are required to maintain the confidentiality of your information.<hr/>
            watchlist101.com may disclose your personal information, without notice, if we try to do so by the law or in good faith believe that such action is necessary to: (a)  conform to this addicts of the law or I would legal process served on watchlist101.com ; (b)  protect and defend the right or property of watchlist101.com; and/or (c)  extringent circumstances to protect the personal safety of users of watchlist101.com,  what are the Public.<hr/>
          </p>
          <h4>Automatically Collected Information</h4>
          <p>
            Information about your computer hardware and software may be automatically selected by watchlist101.com. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide General statistics regarding the use of watchlist101.com website.
          </p>
          <h4>Right to Deletion</h4>
          <p>
            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:<br/>
              delete your personal information for my record; and<br/>
              direct any service providers to delete your personal information from their records<br/>
            Please note that we may not be able to comply with these requests to delete your personal information that is necessary to:<br/>
              Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service  requested by you, or reasonably anticipated within the contract business relationship with you, or otherwise perform a contract between you and us;<br/>
              Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute these responsible for that activity;<br/>
              Debug to identify and repair errors that impair existing intended functionality;<br/>
              Exercise free speech, ensure the right of another customer exercise his or her right of free speech, or exercise another right provided for by law;<br/>
              Comply with the California Electronic Communications Privacy Act;<br/>
              Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that are here's to all the applicable effects and privacy laws, when our deletion of information is likely to render and possible or seriously impair the achievement of such research, provided we have obtained your informed consent;<br/>
              Comply with an existing legal obligation; or<br/>
              Otherwise use your personal information, internally, in a lawful manner that are compatible with the context in which provided information.<br/>
          </p>
          <h4>Children Under Thirteen</h4>
          <p>
            watchlist101.com does not knowingly collect personally identifiable information from children under the age of 13 if you are under the age of 13, you must ask your parent or Guardian for permission to use this website.  Or if you are under 13, please do not provide any information on this website. YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THIS WEB SITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS THIS WEB SITE FOR ANY REASON. DUE TO THE AGE RESTRICTIONS FOR USE OF THIS WEB SITE, NO INFORMATION OBTAINED BY THIS WEB SITE, FALLS WITHIN THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) AND IS NOT MONITORED AS DOING SO.
          </p>
          <h4>External Data Storage Sites</h4>
          <p>
            We may store your data on servers provided by third-party hosting vendors with him we have contracted.
          </p>
          <h4>Changes to this Statement</h4>
          <p>
            watchlist101.com  reserves the right to change privacy policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and or by updating any privacy information on this page. Your continued use of the site and or services available through the site after such modifications will constitute your: (a)  acknowledgement of the modified privacy policy; (b)  agreement to abide and be bound by that policy.
          </p>
          <h4>Dispute Resolution</h4>
          <p>
            If you have any complaints regarding our compliance with this privacy policy, you should first Contact us. We will investigate and attempt to resolve complaints and disputes regarding use and disclosure of personal information in accordance with the privacy policy. 
          </p>
          <h4>Contact Information</h4>
          <p>
            watchlist101.com  welcomes your questions or comments regarding the statement of privacy. If you believe that watchlist101.com  has not  adhered to this statement, please contact watchlist101.com at: 
            info@watchlist101.com
          </p>
          
          <Grid container spacing={1}>
            <Grid item xs={11}><h6>effective as of October 28th, 2021</h6></Grid>
            <Grid item xs={1}><a href='/'><HomeIcon  fontSize='large' /></a></Grid>
          </Grid>

        </div>

    );
};

export default PrivacyPolicy;
