
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// locals
import Monitor from './MonitorPage';

export const MainMonitor = ({isAuthenticated, userWatchlist}) => {

    let history = useHistory();
    history.push(history.location.pathname)
    // console.log(history.location.pathname)

    const watchlistLength = userWatchlist ? userWatchlist.length : 0;
    const [openAlert, setOpenAlert] = useState(watchlistLength > 0 ? false : true);

    if (watchlistLength > 0) {
        return (
            <div>
                <Monitor />
            </div>
        )
    } else {
        return (
            <div>
                <Collapse in={openAlert}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => { setOpenAlert(false) }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 1 }}
                    severity="info"
                    >
                    <h6>
                        You don't have a watchlist to monitor.
                        To create a watchlist visit the <span style={{color: 'blue'}}>MAKE</span> tab.
                    </h6>
                    </Alert>
                </Collapse>

            <Monitor />
            </div>
        )    
    }
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userWatchlist: state.auth.watchlistData,
});
export default connect( mapStateToProps ) (MainMonitor);

