
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL;

export const loadCoins = () => dispatch => {

    const url = `${API_URL}/api/coinData/`;

    axios.get(url, {params: {}})
    .then( (res) => {
        dispatch({
            type: 'LOADING_COINS_SUCCESS',
            payload: res.data.resList,
        });
        // console.log(res.data.resList)
    }).catch( (err) => {
        dispatch({
            type: 'LOADING_COINS_FAIL',
        });
        // console.log(err)
    })
};
