
import React, { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Snackbar } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

import { Bubble } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(
    LinearScale,
    PointElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);


export function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
}

export function setRadius(n) {
    if (n < 10) {
        return 10;
    } else if (n > 150) {
        return 150;
    } else {
        return n;
    }
}

const colors = ['#4caf50', '#f44336']

const PriceBubble = ({
    listName = 'Wish',
    tkrList = ["AAPL", "AMZN", "MSFT", "GOOGL", "FB"],
    period = '1mo',
    chartRef = null,
    width = "100%",
    height = "80vh",
    handleRadius,
}) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(false);
    const [serverResult, setServerResult] = useState({
        results: [],
        beError: '',
        failedList: [],
        successList: [],
    });

    const [error, setError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [alertMsg, setAlertMsg] = useState();
    const [alertLevel, setAlertLevel] = useState(0)


    // read market data from backend
    const url = `${API_URL}/api/marketDataRadius/`;
    const params = {
        "listName": listName,
        "tkrList": tkrList.join(','),
        "period": period
    };

    const fetchData = () => {
        if (tkrList.length > 0) {
            axios.get(url, {params: params})
            .then( res => setServerResult({
                results: res.data.results,
                beError: res.data.error,
                failedList: res.data.failedList,
                successList: res.data.successList,
            }))
            .catch( err => {
                setServerResult({
                    results: [],
                    beError: '',
                    failedList: tkrList,
                    successList: []
                });
                setError(err);
                setAlertLevel(1);
            })
            .finally( () => setLoading(false) );    
        }
    };
    useEffect( () => {
        setLoading(true);
        setAlertLevel(0);
        fetchData();
    }, [tkrList, period]);

    // if backend error is not null, set alert level accordingly
    useEffect( () => {
        if (serverResult.beError !== '' && serverResult.successList.length === 0) {
            setAlertLevel(2);
        } else if (serverResult.beError !== '' && serverResult.failedList.length > 0) {
            setAlertLevel(3);
        }

    }, [serverResult.beError]);


    // set alert message according to alert level 
    useEffect( () => {
        if (alertLevel === 1) {
            setAlertMsg('Failed to read market data. Please try again later.');
            setShowModal(true);

        } else if (alertLevel === 2) {
            setAlertMsg('Failed to read market data. Please try  again later.');
            setShowModal(true);
        } else if (alertLevel === 3) {
            setAlertMsg('Failed to read market data for ' + serverResult.failedItems.join(', ') );
            setShowModal(true);
        }
    }, [alertLevel]);


    useEffect( () => {
        handleRadius(serverResult.results);
    }, [serverResult.results]);

    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: 'blue',
                font: {
                    weight: 'bold',
                    size: '14'
                },
                formatter: function(value, context) {
                    return value.tkr;
                },
                align: function(value) {
                    if (value.r < 10) {
                        return 'end';
                    } else {
                        return 'center';
                    }
                },
            },
            legend: {
                display: false,
                // position: 'top',
            },
            title: {
                // display: true,
                text: 'Percent increase or decrease over period'
            },
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                display: false,
            },
            x: {
                display: false,
            },
        },
    };

    const chartData = {
        labels: false,
        datasets: serverResult.results.map( (d, index) => ({
                data: [{
                    x: getRndInteger(0, 100),
                    y: getRndInteger(0, 100),
                    r: setRadius(Math.abs(d.Radius)),
                    tkr: d.Symbol,
                }],
                backgroundColor: d.Radius > 0 ? colors[0] : colors[1],
                borderColor: 'white',
                borderWidth: 1,
                hoverBackgroundColor: 'gray',
        })),
    };


    return (
        <div>
        { loading?
            <div style={{
                width: width,
                height: height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}><CircularProgress /></div>:
            <div>
                <div style={{
                    width: width,
                    height: height,
                    paddingRight: '20px',
                }}>
                    <Bubble width={width} height={height} options={options} data={chartData} ref={chartRef} />
                </div>
            </div>
        }
        <div>
            <Snackbar
                open={showModal}
                autoHideDuration={5000}
                onClose={() => setShowModal(false)}
                message={alertMsg}
                action={
                    <Fragment>
                    <Button color="secondary" size="small" onClick={() => setShowModal(false)}>
                        DISMISS
                    </Button>
                    </Fragment>
                }
            />
        </div>
        </div>
    );  
}

export default PriceBubble;