
const initialState = {
    navbarSize: 50,
    topbarSize: 48,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case 'SET_NAVBAR_SIZE':
            return {
                ...state,
                navbarSize: action.navbarSize
            }
        case 'SET_TOPBAR_SIZE':
            return {
                ...state,
                topbarSize: action.topbarSize
            }
        default:
            return state;
    }
}
