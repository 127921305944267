
import { Checkbox, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export const TopBar = ({
    interval = 'D',
    darkMode = true,
    indexList,
    selectList,
    handleIntervalChange,
    handleWatchlist,
    handleThemeChange,
}) => {

    const intervalList = ['D', 'W', 'M']

    return (
        <div>
            <Grid container sx={{marginTop: '1px'}}>
            <Grid item xs={1}>
                <FormControl variant="filled" size="small" fullWidth>
                <InputLabel id="interval">Interval</InputLabel>
                <Select
                    value={interval}
                    onChange={handleIntervalChange}
                >
                    { intervalList.map ( (item, idx) => {
                        return <MenuItem value={item} key={idx}>{ item }</MenuItem>
                    })}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={1}>
                <FormControlLabel control={
                    <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                }
                checked={darkMode}
                onChange={handleThemeChange}
                label='Dark'
                labelPlacement="start"
                />

            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={3}>
                <FormControl variant="filled" size="small" fullWidth>
                <InputLabel id="userlist">Watchlist</InputLabel>
                <Select
                    value={selectList}
                    onChange={handleWatchlist}
                >
                    { indexList.map ( (item, idx) => {
                        return <MenuItem value={item} key={idx}>{ item }</MenuItem>
                    })}
                </Select>
                </FormControl>
            </Grid>
            </Grid>
        </div>
    )
}
