
// get and display user watchlist
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { saveAs } from 'file-saver';
import Snackbar from '@mui/material/Snackbar';

// local utils
import CompareChart from '../charts/Compare';
import PricePie from '../charts/PricePie';
import PriceBubble from '../charts/PriceBubble';
import { RightBar, TopBar } from '../bars/MonitorBars';
import { useWindowDimensions } from '../pages/Utils';


// ** remove activelistItems which is company names*

const Monitor = ({ isAuthenticated, userWatchlist, publicWatchlist, navbarSize, topbarSize }) => {

    // console.log(publicWatchlist);


    const [stateWatchlist, setStateWatchlist] = useState([]);
    useEffect( () => {
        if (userWatchlist.length > 0) {
            setStateWatchlist(userWatchlist)
        } else {
            let pl = publicWatchlist.filter(v => v.Name.includes('sample'));

            setStateWatchlist(pl.length > 0 ? pl : publicWatchlist.slice(0, 2));
        }
    }, [userWatchlist, publicWatchlist]);

    
    const [activeList, setActiveList] = useState([]);
    const [listNames, setListNames] = useState([]);
    const [activeListName, setActiveListName] = useState(null);
    const [activeListSymbols, setActiveListSymbols] = useState([]);
    const [activeListItems, setActiveListItems] = useState([]);
    const [activeChart, setActiveChart] = useState('pie');
    const [loading, setLoading] = useState(false);
    const [tweetText, setTweetText] = useState('');
    const [openSnack, setOpenSnack] = useState(false);

    useEffect( () => {
        if (stateWatchlist.length > 0) {
            setActiveList(stateWatchlist[0].Results);
            setListNames(stateWatchlist.map(v => v.Name));
            setActiveListName(stateWatchlist[0].Name);
            setActiveListSymbols(stateWatchlist[0].Results.map(v => v.Symbol));
            setActiveListItems(stateWatchlist[0].Results.map(v => v.Name));
        }
    }, [stateWatchlist]);

    // console.log(activeList.map(item => item['CurrentPrice']))

    
    // handle list click
    const handleListClick = (e) => {
        let listSymbols = stateWatchlist.find(v => v.Name === e).Results.map( r => r.Symbol );
        let listItems = stateWatchlist.find(v => v.Name === e).Results.map( r => r.Name );
        let listText = `chart of the day: $${listSymbols.join(', $')}\n\n`;
        setActiveList(stateWatchlist.find(v => v.Name === e).Results);
        setActiveListName(e);
        setActiveListSymbols(listSymbols);
        setActiveListItems(listItems);
        setTweetText(listText);
    }

    // console.log(activeList.map(item => item.CurrentPrice))

    // handle period change
    const [period, setPeriod] = useState('6mo');
    const handlePeriodChange = (e) => {
        setPeriod(e);
    }

    // setting line chart
    const handleChart = (e) => { 
        setActiveChart(e);
    };

    // handle radius display
    const [radiusList, setRadiusList] = useState([]);
    const handleRadius = (e) => {
        setRadiusList(e);
    }

    // handle chart image download
    const chartRef = useRef(null);

    const handleChartDownload = () => {
        // console.log('downloading chart');
        if (chartRef.current !== null) {
            var canvas = chartRef.current.ctx.canvas;
            var img = canvas.toDataURL("image/png");
            saveAs(img, `${activeListName}.png`);

        }
    };

    const handleChartCopy = () => {
        // console.log('copy chart');
        if (chartRef.current !== null) {
            var canvas = chartRef.current.ctx.canvas;

            try {
                canvas.toBlob(blob => navigator.clipboard.write([new window.ClipboardItem({'image/png': blob})]));
                setOpenSnack(true);
            } catch (error) {
                connect.error(error)
            }    
        }
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };


    // setting table dimensions *** potential to improve ***
    const mainBoxHeight = useWindowDimensions().height - (navbarSize + topbarSize + 10);

    return (
        <div> 
        { loading ?
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <CircularProgress />
            </div> :
            <Stack spacing={1}>
                <div></div>
                <TopBar
                    listNames={ listNames }
                    activeList={ activeListName }
                    activePeriod={ period }
                    handleChart = { handleChart}
                    handleListClick={ handleListClick }
                    handlePeriod={ handlePeriodChange }
                    tweetText={ tweetText }
                    handleChartDownload={ handleChartDownload }
                    handleChartCopy={ handleChartCopy }
                ></TopBar>
                <Grid container sx={{
                    minHeight: mainBoxHeight,
                    maxHeight: mainBoxHeight,
                    backgroundColor: 'honeydew',
                }}>
                    <Grid item xs={10}>{ activeChart === 'line' ?
                        <CompareChart
                        listName={ activeListName }
                        tkrList={ activeListSymbols }
                        period={ period }
                        chartRef={ chartRef }
                        width= "100%"
                        height= { mainBoxHeight }
                        ></CompareChart> : activeChart === 'pie' ?
                        <PricePie
                        activeList={ activeList }
                        chartRef={ chartRef }
                        width= "100%"
                        height= { mainBoxHeight }
                        ></PricePie> :
                        <PriceBubble
                        listName={ activeListName }
                        tkrList={ activeListSymbols }
                        period={ period }
                        chartRef={ chartRef }
                        width= "100%"
                        height= { mainBoxHeight }
                        handleRadius={ handleRadius }
                        ></PriceBubble>
                    }
                    </Grid>
                    <Grid item xs={2} style={{
                        height: mainBoxHeight,
                        backgroundColor: '#c0c0c0',
                        overflow: 'auto'
                    }}>
                        <RightBar
                        activeList = { activeList }
                        indexList = { activeListSymbols }
                        radiusList = { radiusList }
                        chartType = { activeChart }
                        ></RightBar>
                    </Grid>
                </Grid>
            </Stack>
        }
        <div>
            <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            message="Image copied to clipboard"
            />
        </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userWatchlist: state.auth.watchlistData,
    publicWatchlist: state.publicList.publicList,
    navbarSize: state.barSize.navbarSize,
    topbarSize: state.barSize.topbarSize,
});

export default connect( mapStateToProps ) (Monitor);