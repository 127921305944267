
import { Paper, styled } from "@mui/material";

export const NoteCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(5),
    // paddingTop: theme.spacing(5),
    // paddingBottom: theme.spacing(5),
    textAlign: 'Left',
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.grey[grayindex],
    backgroundColor: 'honeydew',
}));

export const HeaderCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[200],
}));

export const TextCard = styled(Paper)(({ grayindex=50, theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(10),
    // paddingTop: theme.spacing(5),
    // paddingBottom: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[grayindex],
    // backgroundColor: 'black',
    elevation: 24,
    rounded: 'true',
}));


export const ListCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[50],
}));

export const ChartCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: 'black',
}));

export const FooterCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(87, 154, 156, 0.295)',
}));

export const MakeListCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(87, 154, 156, 0.295)',
}));
